<template src="./template.html"></template>

<script>
export default {
  name: "NewsListPagination",
  props: {
    total_page_count: {
      require: true
    }
  },
  data() {
    return {
      page_array: []
    }
  },
  methods: {
    SetPageArray() {
      let page_array = []
      if (this.now_page - 2 > 0 && this.now_page + 2 <= this.total_page_count) {
        //有頭有尾
        for (let i = this.now_page - 2; i < this.now_page + 3; i++) {
          page_array.push(i)
        }
      }
      else if (this.now_page - 2 <= 0 && this.now_page + 2 <= this.total_page_count) {
        //沒頭有尾
        for (let i = 1; i < 6; i++) {
          page_array.push(i)
        }
      }
      else if (this.now_page - 2 > 0 && this.now_page + 2 > this.total_page_count) {
        //有頭沒尾
        for (let i = this.total_page_count; i > 0; i--) {
          if (page_array.length < 5) {
            page_array.push(i)
          }
        }
        page_array.reverse()
      }
      else {
        //沒頭沒尾
        for (let i = 1; i < this.total_page_count + 1; i++) {
          page_array.push(i)
        }
      }
      this.page_array = page_array
    }
  },
  mounted() {
    this.SetPageArray()
  },
  watch: {
    "$route"() {
      this.SetPageArray()
    }
  },
  computed: {
    now_page() {
      return parseInt(this.$route.params.page)
    }
  }
}
</script>